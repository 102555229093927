







































































































import { Component, Vue } from 'vue-property-decorator'
import FormError from '@bertazzoni/common/src/components/FormError.vue'
import CheckBox from '@bertazzoni/common/src/components/CheckBox.vue'
import Icons from '@bertazzoni/common/src/components/Icons.vue'
import { namespace } from 'vuex-class'
import { UserCredentials } from '@bertazzoni/common/src/models/user.model'
import { From } from '@bertazzoni/common/src/helpers/enums/from'
import UsersService from '@bertazzoni/common/src/services/UsersService'
import { UserType } from '@bertazzoni/common/src/models/user.model'
// import LogRocket from 'logrocket'
const Auth = namespace('Auth')

@Component({
  components: {
    FormError,
    CheckBox,
    Icons
  }
})
export default class Login extends Vue {
  private userCredentials: UserCredentials = { username: '', password: '', stayConnected: false }
  private CONTACT_EMAIL = process.env.VUE_APP_CONTACT_EMAIL
  private loading = false
  private message = ''

  @Auth.Getter
  private isLoggedIn!: boolean

  @Auth.Action
  private signIn!: (userCredentials: UserCredentials) => Promise<void>
  private isFoPage: boolean = null

  created() {
    // LogRocket.init('mphway/bertazzoni')
    // This is an example script - don't forget to change it!
    // LogRocket.identify('mphway:bertazzoni:x5l0WdMkWjrzuN8Bthg5', {
    // name: 'Nelson Caraballo',
    // email: 'nelsoncaraballo@elecsis.co',

    // Add your own custom user variables here, ie:
    // subscriptionType: 'pro'
    // })
    this.isFoPage = this.$store.getters['Common/from'] === From.frontOffice
    if (this.isLoggedIn) {
      if (this.isFoPage) {
        this.$router.push('/dashboard')
      } else {
        this.checkUserType()
      }
    }
  }

  async checkUserType(): Promise<void> {
    const currentUser = await UsersService.findMe()
    if (
      currentUser &&
      (currentUser.type === UserType.bertazzoniAdmin || currentUser.type === UserType.countryAdmin)
    ) {
      this.$router.push('/module-list')
    } else {
      this.$router.push('/users')
    }
  }

  async handleLogin() {
    try {
      await this.$validator.validateAll()
    } catch (error) {
      this.loading = false
      return
    }
    if (this.userCredentials.username && this.userCredentials.password) {
      try {
        this.loading = true
        await this.signIn(this.userCredentials)
        if (this.$store.getters['Common/from'] === From.frontOffice) {
          this.$router.push('/dashboard')
        } else {
          this.checkUserType()
        }
      } catch (error) {
        this.message = error
      }
      this.loading = false
    }
  }
}
